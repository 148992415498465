<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.grade_name"
        placeholder="请输入年级"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
      >
      </el-date-picker>

      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="(v, i) in colleges"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.is_on"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="已下架" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @sort-change="changeSort"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center" sortable>
        <template slot-scope="scope"> {{ scope.row.college.name }} </template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center" sortable>
        <template slot-scope="scope">{{ scope.row.major.name }} </template>
      </el-table-column>
      <el-table-column label="年级" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.grade.name }} </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_year }}-{{ scope.row.school_year - 0 + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="自动升级时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.class_grade_update_at
              ? scope.row.class_grade_update_at
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_on">正常</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handlerStudent(scope.row)"
            >学生管理</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleCheckCurriculum(scope.row)"
            >查看课表</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="请选择学年" prop="school_year">
          <el-date-picker
            v-model="school_year"
            placeholder="请选择学年"
            value-format="yyyy"
            type="year"
            style="width: 200px"
            @change="
              getCollegeList(),
                (form.grade_id = null),
                (form.college_id = null),
                (form.major_id = null)
            "
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="请选择院系" prop="college_id">
          <el-select
            v-model="form.college_id"
            placeholder="请选择院系"
            @change="
              getMajorList(1), (form.major_id = null), (form.grade_id = null)
            "
            clearable
            style="width: 200px"
            class="filter-item"
          >
            <el-option
              v-for="(v, i) in colleges"
              :key="i"
              :label="v.name"
              :value="v.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="请选择专业" prop="major_id">
          <el-select
            v-model="form.major_id"
            placeholder="请选择专业"
            clearable
            style="width: 200px"
            @change="getGradeList(1), (form.grade_id = null)"
          >
            <el-option
              v-for="item in majors"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="请选择年级" prop="grade_id">
          <el-select
            v-model="form.grade_id"
            placeholder="请选择年级"
            style="width: 200px"
          >
            <el-option
              v-for="item in grades"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="请输入班级" prop="name">
          <el-input
            type="text"
            v-model="form.name"
            clearable
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="班级自动升级时间:" prop="">
          <el-date-picker
            v-model="form.class_grade_update_at"
            type="date"
            class="filter-item"
            value-format="yyyy-MM-dd"
            format="MM-dd"
            placeholder="班级自动升级时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      school_years: [],
      colleges: [],
      grades: [],
      majors: [],
      school_year: null,
      listQuery: {
        page: 1,
        limit: 10,
        grade_name: "",
        school_year: "",
        college_id: "",
        is_on: "",
        // school_name_sort: "",
        college_name_sort: "",
        major_name_sort: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        college_id: "",
        major_id: "",
        grade_id: "",
        name: "",
        school_year: "",
      },
      rules: {
        school_year: [
          { required: true, message: "学年不能为空", trigger: "change" },
        ],
        grade_id: [
          { required: true, message: "年级不能为空", trigger: "change" },
        ],
        college_id: [
          { required: true, message: "院系不能为空", trigger: "change" },
        ],
        major_id: [
          { required: true, message: "专业不能为空", trigger: "change" },
        ],
        name: [{ required: true, message: "班级不能为空", trigger: "change" }],
      },
      btnLoading: false,
      grade_id: "",
    };
  },
  watch: {
    school_year(newV, oldV) {
      console.log(newV, oldV, "=========");
      this.form.school_year = newV;
    },
    grade_id(newV, oldV) {
      console.log(newV, oldV, "=========");
      this.form.grade_id = newV;
    },
  },
  created() {
    this.getCollegeList();
    this.getList();
  },
  methods: {
    //排序
    changeSort(e) {
      console.log(e);
      // this.listQuery.school_name_sort = "";
      this.listQuery.college_name_sort = "";
      this.listQuery.major_name_sort = "";
      if (e.order == null) {
        this.getList();
        return;
      }
      switch (e.column.label) {
        case "学校":
          if (e.order == "ascending") {
            this.listQuery.school_name_sort = 0;
          } else {
            this.listQuery.school_name_sort = 1;
          }
          break;
        case "院系":
          if (e.order == "ascending") {
            this.listQuery.college_name_sort = 0;
          } else {
            this.listQuery.college_name_sort = 1;
          }
          break;
        case "专业":
          if (e.order == "ascending") {
            this.listQuery.major_name_sort = 0;
          } else {
            this.listQuery.major_name_sort = 1;
          }
          break;
        default:
          break;
      }
      this.getList();
    },

    handleCheckCurriculum(row) {
      this.$router.push(`/school/check_curriculum?class_id=${row.id}&type=1`);
    },

    changeSelect(e) {
      console.log(11111111);
      console.log(e);
    },
    handlerStudent(row) {
      this.$router.push(
        `/school/student_manager?colleage_id=${row.college_id}&id=${row.id}&grade_id=${row.grade_id}&study_year=${row.major.study_year_num}`
      );
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
          is_on: 1,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getGradeList(key) {
      request({
        url: "/api/schoolend/school/gradeList",
        method: "get",
        params: {
          limit: 999999999999999,
          school_year: this.form.school_year,
          major_id: this.form.major_id,
        },
      }).then((response) => {
        this.grades = response.data.data;
        console.log(response.data.data);
        if (this.form.grade_id && key != 1) {
          this.form.grade_id = null;
        }
        // this.$forceUpdate()//强制更新
      });
    },
    getMajorList(key) {
      request({
        url: "/api/schoolend/school/majorList",
        method: "get",
        params: {
          limit: 999999999999999,
          college_id: this.form.college_id,
        },
      }).then((response) => {
        this.majors = response.data.data;
        // if (this.form.major_id && key != 1) {
        //   this.form.major_id = null;
        // }
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/school/classList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        college_id: "",
        major_id: "",
        grade_id: "",
        name: "",
        school_year: "",
      };
    },
    handleCreate() {
      this.resetForm();
      this.grade_id = "";
      this.school_year = "";
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      console.log(row);
      this.resetForm();
      this.form = Object.assign({}, row);
      this.school_year = row.school_year;
      this.form.school_year = row.school_year-0;
      this.grade_id = row.grade_id;
      // if (this.form.school_year) {
      this.getGradeList(1);
      // }
      this.getMajorList(1);
      // console.log();
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/school/classStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/school/classUpDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    // 获取省
    getProvince() {
      return request({
        url: "/api/common/area/index",
        method: "get",
      }).then((res) => {
        this.provinces = res.data;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
